<template>
  <div style="position:relative;">

    <!-- :: BLOCK CONTENT -->
    <div :style="layoutStyle"
         class="d-flex justify-center"
         style="position : relative">

      <v-sheet
          width="1240"
          color="transparent"
          height="100%"
          style="margin-bottom: 200px"
          class="px-3"
      >

        <div class="d-flex justify-center mb-5 mt-10">
          <div class="d-flex align-center">
            <avalon-button
                @click="isDocument = false; searchFailed = false"
                :text="`${$t('Certificate')}`"
                block-style="light_1"
                class="mt-5"
                :fill-area="SM"
                :buttonStyle="!isDocument ? 'primary' : 'secondary'"
            />
            <avalon-button
                @click="isDocument = true; searchFailed = false"
                :text="`${$t('Document')}`"
                block-style="light_1"
                class="mt-5"
                :fill-area="SM"
                :buttonStyle="isDocument ? 'primary' : 'secondary'"
            />

          </div>
        </div>


        <div v-if="!isDocument">

          <h1 class="text-center" :style="CONFIG_TEXT_CSS(  'light_1' , 'block' , 'title')">{{ $t('SearchCertificate') }}</h1>
          <h3 class="text-center" :style="CONFIG_TEXT_CSS(  'light_1' , 'block' , 'subtitle')" >{{ $t('SearchCertificateDescription') }}</h3>
          <div class="d-flex justify-center mt-10">
            <v-sheet color="transparent" max-width="600px" width="100%"  >
              <div  >
                <avalon-text-field
                    v-model="certificateNumber"
                    icon="mdi-magnify"
                    :block="DEFAULT_BLOCK"
                    :placeholder="$t('CertificateNumber')"
                    @input="certificateError = null"
                    :width="!SM ? '100%' : null"
                    :error-messages="certificateError"
                />
                <avalon-button
                    @click="findCertificate"
                    :text="`${$t('SearchCertificate')}`"
                    block-style="light_1"
                    icon="mdi-magnify"
                    class="mt-5"
                    fill-area
                />
              </div>

              <v-simple-table
                  key="table"
                  v-if="certificate.uuid" class="wsHoverLight mt-10">
                <tbody
                >
                <tr>
                  <td width="200px"
                      style="border-top-left-radius: 8px"
                      :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; background : ${GET_AVALON_COLOR('medium')}`"
                  >
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ $t('StudentName') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important`"
                      style="border-top-right-radius: 8px"
                  >
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )" >{{ certificate.user_name }}</h5>
                  </td>
                </tr>

                <tr>
                  <td  :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; border-top : none !important; background : ${GET_AVALON_COLOR('medium')}`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ $t('CertificateNumber') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important; border-top : none !important;`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ certificate.number}}</h5>
                  </td>
                </tr>
                <tr>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; border-top : none !important; background : ${GET_AVALON_COLOR('medium')}`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )" >{{ $t('EducationStart') }}</h5>
                  </td>
                  <td  :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important; border-top : none !important;`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ certificate.date_registered ? PARSE_DATE(certificate.date_registered , false , true ) : null }}</h5>
                  </td>
                </tr>
                <tr>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; border-top : none !important; background : ${GET_AVALON_COLOR('medium')}`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )" >{{ $t('CertificateIssuedAt') }}</h5>
                  </td>
                  <td  :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important; border-top : none !important;`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ certificate.date ?  PARSE_DATE(certificate.date, false , true)   : null}}</h5>
                  </td>
                </tr>
                <tr>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; border-top : none !important; background : ${GET_AVALON_COLOR('medium')}`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )" >{{ $t('CourseName') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important; border-top : none !important;`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ certificate.course_name }}</h5>
                  </td>
                </tr>
                <tr>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; border-top : none !important; background : ${GET_AVALON_COLOR('medium')}`"
                      style="border-bottom-left-radius: 8px"
                  >
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )" >{{ $t('SchoolName') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important; border-top : none !important;`"
                      style="border-bottom-right-radius: 8px"
                  >
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )" class="d-flex align-center">
                      {{ certificate.business_name }}
                    </h5>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>

              <div key="noData"
                   class="mt-16" v-else-if="searchFailed">
                <div class="d-flex justify-center">
                  <v-icon
                      :size="CONFIG_FONT_VARIABLE('size','h3') * 1.5"
                      :color="CONFIG_PARAM_COLOR('styles' , 'light_1' , 'h3' )"
                      class="mx-auto text-center" >mdi-magnify</v-icon>
                </div>

                <h4 class="text-center font-weight-regular"
                    :style="CONFIG_TEXT_CSS(  'light_1' , 'h3' )"

                >{{ $t('certificates.not_found') }}</h4>
              </div>
            </v-sheet>
          </div>
        </div>

        <div v-else>
          <h1 class="text-center" :style="CONFIG_TEXT_CSS(  'light_1' , 'block' , 'title')">{{ $t('documentflow.document.check') }}</h1>
          <h3 class="text-center" :style="CONFIG_TEXT_CSS(  'light_1' , 'block' , 'subtitle')" >{{ $t('documentflow.document.check_description') }}</h3>
          <div class="d-flex justify-center mt-10">
            <v-sheet color="transparent" max-width="600px" width="100%"  >
              <div  >

                <avalon-text-field
                    v-if="!document.uuid"
                    v-model="documentNumber"
                    icon="mdi-magnify"
                    :block="DEFAULT_BLOCK"
                    :placeholder="$t('documentflow.document.public_key')"
                    @input="certificateError = null"
                    :width="!SM ? '100%' : null"
                    :error-messages="certificateError"
                />

                <avalon-button
                    v-if="!document.uuid"
                    @click="findDocument"
                    :text="`${$t('documentflow.document.find')}`"
                    block-style="light_1"
                    icon="mdi-magnify"
                    class="mt-5"
                    fill-area
                />

                <ws-file-uploader
                    v-if="!document.uuid"
                    @success="setDocument"
                    class="mt-5"
                    :request-data="{
                        route : `documentflow/check/document`,
                  }"
                />


                <avalon-button
                    v-if="document.uuid"
                    @click="document = {}"
                    :text="`${$t('documentflow.document.find_again')}`"
                    block-style="light_1"
                    icon="mdi-magnify"
                    class="mt-5"
                    fill-area
                />
              </div>

              <v-simple-table
                  key="table"
                  style="background-color: transparent !important"
                  v-if="document.uuid" class="wsHoverLight mt-10">
                <tbody
                >
                <tr>
                  <td width="200px"
                      style="border-top-left-radius: 8px"
                      :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; background : ${GET_AVALON_COLOR('medium')}`"
                  >
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ $t('Name') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important`"
                      style="border-top-right-radius: 8px"
                  >
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )" >{{ document.name }}</h5>
                  </td>
                </tr>

                <tr>
                  <td  :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; border-top : none !important; background : ${GET_AVALON_COLOR('medium')}`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ $t('documentflow.document.public_key') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important; border-top : none !important;`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ document.public_key}}</h5>
                  </td>
                </tr>

                <tr>
                  <td  :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; border-top : none !important; background : ${GET_AVALON_COLOR('medium')}`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ $t('Type') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important; border-top : none !important;`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ document.type_name}}</h5>
                  </td>
                </tr>

                <tr>
                  <td  :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; border-top : none !important; background : ${GET_AVALON_COLOR('medium')}`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ $t('documentflow.document.code') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important; border-top : none !important;`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ document.code}}</h5>
                  </td>
                </tr>

                <tr>
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; border-top : none !important; background : ${GET_AVALON_COLOR('medium')}`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )" >{{ $t('Date') }}</h5>
                  </td>
                  <td  :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important; border-top : none !important;`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ document.date ? MONTH_DAY_TIME(document.date , false , true ) : null }}</h5>
                  </td>
                </tr>

                <tr v-if="document.signators && document.signators.length">
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; border-top : none !important; background : ${GET_AVALON_COLOR('medium')}`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )" >{{ $t('Signators') }}</h5>
                  </td>
                  <td  :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important; border-top : none !important;`">
                    <div v-for="(signator,i) in document.signators" :key="i" class="mb-2" >
                      <h5 class="font-weight-bold" :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ signator.name }}</h5>
                      <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ signator.position_name }}</h5>
                    </div>
                  </td>
                </tr>

                <tr v-if="document.aprovals && document.aprovals.length">
                  <td :style="`border : 1px solid ${GET_AVALON_COLOR('accent')} ; border-top : none !important; background : ${GET_AVALON_COLOR('medium')}`">
                    <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )" >{{ $t('AprovedBy') }}</h5>
                  </td>
                  <td  :style="`border : 1px solid ${GET_AVALON_COLOR('accent')}; border-left : none !important; border-top : none !important;`">
                    <div v-for="(signator,i) in document.aprovals" :key="i" class="mb-2" >
                      <h5 class="font-weight-bold" :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ signator.name }}</h5>
                      <h5 :style="CONFIG_TEXT_CSS(  'light_1' , 'p' )">{{ signator.position_name }}</h5>
                    </div>
                  </td>
                </tr>


                </tbody>
              </v-simple-table>

              <div key="noData"
                   class="mt-16" v-else-if="searchFailed">
                <div class="d-flex justify-center">
                  <v-icon
                      :size="CONFIG_FONT_VARIABLE('size','h3') * 1.5"
                      :color="CONFIG_PARAM_COLOR('styles' , 'light_1' , 'h3' )"
                      class="mx-auto text-center" >mdi-magnify</v-icon>
                </div>

                <h4 class="text-center font-weight-regular"
                    :style="CONFIG_TEXT_CSS(  'light_1' , 'h3' )"

                >{{ $t('documentflow.document.check_not_found') }}</h4>
              </div>
            </v-sheet>
          </div>
        </div>






      </v-sheet>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "checkDocumentPage",
  components: {},

  data() {
    return {
      certificateNumber : null,
      documentNumber : null,
      certificate : {},
      searchFailed : false,
      certificateError : null,
      isDocument : false,
      document : {}
    }
  },
  computed : {
    ...mapState('avalon',[   'header' ]),


    layoutStyle(  ) {
      let style = ''
      style += `background-color : ${this.GET_AVALON_COLOR('background_main')}` + ';'
      return style
    },
  },
  methods : {
    ...mapActions('certificates' , ['GET_COURSE_CERTIFICATE_PUBLIC']),
    ...mapActions('documentflow' , ['CHECK_DOCUMENT_PUBLIC']),
    setDocument(response) {
      this.document = response
    },

    async findDocument() {


      if ( !this.documentNumber ) {
        this.documentError = this.$t('EnterDocumentNumber')
        return
      }

      let result = await this.CHECK_DOCUMENT_PUBLIC(this.documentNumber)
      if ( !result || result === true ) {
        this.searchFailed = true
        this.document = {}
        return
      }
      this.searchFailed = false
      this.document = result

    },

    async findCertificate() {
      if ( !this.certificateNumber ) {
        this.certificateError = this.$t('EnterCertificateNumber')
        return
      }

      let result = await this.GET_COURSE_CERTIFICATE_PUBLIC(this.certificateNumber)
      if ( !result || result === true ) {
        this.searchFailed = true
        this.certificate = {}
        return
      }
      this.searchFailed = false
      this.certificate = result

    },
    async initPage() {
      if ( !this.$store.state.QUERY.id  ) {
        return
      }
      this.certificateNumber = this.$store.state.QUERY.id
      if ( this.certificateNumber ) {
        this.findCertificate()
      }

      this.documentNumber = this.$store.state.QUERY.document
      if ( this.documentNumber ) {
        this.isDocument = true
        this.findDocument()
      }

    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>