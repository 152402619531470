import { render, staticRenderFns } from "./checkDocumentPage.vue?vue&type=template&id=72549c3a&scoped=true&"
import script from "./checkDocumentPage.vue?vue&type=script&lang=js&"
export * from "./checkDocumentPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72549c3a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VIcon,VSheet,VSimpleTable})
